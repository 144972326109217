.tabPanel {
    width: 300;
    height: 300;
    padding: 10;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

#imageContainer {
    overflow: scroll;
    border: 1px solid black;
    width: 550px;
    height: 285px;
}
