.box {
    width: 300px;
    padding: 10px;
    margin-bottom: 6px;
    text-align: center;
}

.imageContainer {
    width: 100%;
    position: relative;
    margin-top: 20px;
}

.mover {
    width: 50%;
}

/* 障害物センサー状態 */
/* 右真ん中上 */
.moverState1 {
    position: absolute;
    width: 80%;
    right: 0px;
    z-index: 100;
    bottom: 35px;
}
/* 右上 */
.moverState2 {
    position: absolute;
    width: 80%;
    right: -25px;
    z-index: 100;
    bottom: -10px;
}
/* 右下 */
.moverState3 {
    position: absolute;
    width: 80%;
    right: -25px;
    z-index: 100;
    bottom: -90px;
}
/* 右真ん中下 */
.moverState4 {
    position: absolute;
    width: 80%;
    right: -0px;
    z-index: 100;
    bottom: -115px;
}
/* 左真ん中上 */
.moverState5 {
    position: absolute;
    width: 80%;
    right: 60px;
    z-index: 100;
    bottom: 35px;
}
/* 左上 */
.moverState6 {
    position: absolute;
    width: 80%;
    right: 85px;
    z-index: 100;
    bottom: -10px;
}

/* 左下 */
.moverState7 {
    position: absolute;
    width: 80%;
    right: 85px;
    z-index: 100;
    bottom: -90px;
}
/* 左下 */
.moverState8 {
    position: absolute;
    width: 80%;
    right: 60px;
    z-index: 100;
    bottom: -115px;
}
