.mobileMoverStopTimeBox {
    width: 300px;
    height: 190px;
    padding: 10px;
    overflow-x: auto;
    margin-bottom: 20px;
}

.mapPointFrame {
    font-size: 18px;
    border-bottom: 0.1rem solid;
    display: flex;
}

.mapPointName {
    margin: 8px auto 8px 15px;
}

.mapPointInputFrame {
    margin: 8px;
}

.mapPointInput {
    width: 40px;
    height: 20px;
}

.mapPointTimeName {
    margin: 10px 0px 0px 5px;
}
